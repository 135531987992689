/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import { useLocation } from '@reach/router';

const Solutions = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { modules, seo } = page || {};

  const { search } = useLocation();

  let element;

  useEffect(() => {
    setTimeout(() => {
      element = document.getElementById('filters');
      if (search) {
        element?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 2000);
  });

  return (
    <Layout disabledHeader={true}>
      <PageMeta {...seo} />
      {modules && <ModuleZone {...modules} />}
    </Layout>
  );
};

export default Solutions;

export const pageQuery = graphql`
  query sanitySolutionsHomeQuery {
    page: sanitySolutionsHome {
      seo {
        ...SEO
      }
      modules {
        ...SolutionModules
      }
    }
  }
`;
